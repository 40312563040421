<template>
  <v-row wrap no-gutters>
    <credentials-select
      :key="`${step.id}-credentialName`"
      step-type="JDBC"
      :properties="properties"
      step-label="jdbc"
      :readonly="!canEdit"
      required
      @change="handleChange('credentialName', $event)"
    />
    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="JDBC_ACTIONS"
        :default-value="properties.action"
        step-label="jdbc"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>
    <v-col v-if="!isTransactionAction" cols="12">
      <text-input-with-editor-button
        :key="`${step.id}-query`"
        :rules="[v => !!v || $lang.labels.required]"
        :default-value="properties.query"
        step-label="jdbc"
        required
        :readonly="!canEdit"
        @change="handleChange('query', $event)"
        @openEditor="$emit('openEditor', $event, 'query',
                           { text: '', value: properties.query, parentType: 'query' }
        )"
      />
      <target-object-field
        :key="`${step.id}-targetObject`"
        :show-single-result-toggle="properties.action === 'SELECT'"
        :default-target-object="properties.targetObject"
        :default-single-result="properties.singleResult"
        step-label="jdbc"
        required
        :readonly="!canEdit"
        @change="handleChange($event.key, $event.value)"
      />
    </v-col>
  </v-row>
</template>

<script>
import {
  ActionSelect,
  CredentialsSelect,
  TextInputWithEditorButton,
  TargetObjectField
} from './components'
import StepMixin from './StepMixin'

import { TRANSACTION_ACTIONS, JDBC_ACTIONS } from './constants'
import { getNestedPropertyForSetting } from '@/utils/helpers'

export default {
  name: 'JdbcStep',
  components: {
    ActionSelect,
    CredentialsSelect,
    TextInputWithEditorButton,
    TargetObjectField
  },
  mixins: [StepMixin],
  data() {
    return {
      JDBC_ACTIONS
    }
  },
  computed: {
    isTransactionAction() {
      return TRANSACTION_ACTIONS.includes(this.properties.action)
    }
  },
  created() {
    if (TRANSACTION_ACTIONS.includes(this.properties.query)) {
      this.properties.action = this.properties.query
      this.properties.query = ''
    }
  },
  methods: {
    handleChange(key, val) {
      if (key === 'singleResult' && val === null) return

      if (key.includes('.')) {
        const { nestedObj, lastKey } = getNestedPropertyForSetting(this.properties, key)

        this.$set(nestedObj, lastKey, val)

        return
      }
      this.$set(this.properties, key, val)
      
      if (val && key === 'action' && TRANSACTION_ACTIONS.includes(val)) {
        this.properties.query = val
      } else if (key === 'action') {
        this.$set(this.properties, 'query', null)
      }
    }
  }
}
</script>
